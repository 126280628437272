import React from "react";

import { jsx } from "@emotion/react";
/** @jsx jsx */
import {
  wrapperDiv,
  stepsblock,
  step,
  confidenceWrapper,
  uploadTreatment,
} from "./AnonymousQuoteStart.style";
import QuoteUploadPanel from "../QuoteUploadPanel/QuoteUploadPanel";
import instantQuoteImg from "./images/instant-quote.svg";
import placeOrderImg from "./images/place-order.svg";
import receivePartImg from "./images/receive-part.svg";
import { publicUrl } from "../../utils/constants";
import { useAcceptedFileTypes } from "../../utils/useAcceptedFileTypes";

const AnonymousQuoteStart = () => {
  const { getDisplayNames } = useAcceptedFileTypes();
  const displayNames = getDisplayNames(" | ") || "";

  return (
    <div css={wrapperDiv}>
      <div css={uploadTreatment}>
        <QuoteUploadPanel
          panelScale="large"
          panelStyle="blueGradient"
          instantQuoteButtonContent={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <div>
              <i className="fas fa-cloud-upload" />
              &nbsp;&nbsp;Upload 3D Files
            </div>
          }
        >
          <div>
            <div className="exampleParts" style={{ marginBottom: "32px" }}>
              <img
                src={`${publicUrl}/assets/images/milled-part.png`}
                alt="Example Milled Part"
              />
              <img
                src={`${publicUrl}/assets/images/gear-part.png`}
                alt="Example Gear Part"
              />
              <img
                src={`${publicUrl}/assets/images/lathe-part.png`}
                alt="Example Lathe Part"
              />
            </div>
            <h3>
              Upload a 3D model to see instant pricing, lead time, and DFM
              feedback.
            </h3>
            <span className="supportedFilesList">{`${displayNames} files`}</span>
          </div>
        </QuoteUploadPanel>
        <div css={confidenceWrapper}>
          <div className="confidenceLeft">
            <span>
              <i className="fas fa-lock" />
              &nbsp;&nbsp;All uploads are secure and confidential.
            </span>
          </div>
          <div className="confidenceCenter">
            <span>
              <i className="fas fa-balance-scale" />
              &nbsp;&nbsp;Xometry&apos;s Instant Quoting Engine is covered by US
              Pat. Nos. 11,086,292, 11,347,201, 11,693,388 and 11,698,623. Other patents pending.
            </span>
          </div>
          <div className="confidenceRight">
            <span>
              <i className="fas fa-shield-check" />
              &nbsp;&nbsp;ITAR Registered
            </span>
            <span>
              <i className="fas fa-file-certificate" />
              &nbsp;&nbsp;ISO 9001:2015 and AS9100D Certified
            </span>
          </div>
        </div>
        <div css={stepsblock}>
          <div css={step}>
            <div className="stepIcon">
              <img src={instantQuoteImg} alt="Technology Drawing" />
            </div>
            <div className="stepcontenttitle">
              <div className="steptitle">Get Instant Quote</div>
              <div className="stepcontent">
                <p>
                  To get your instant quote, just upload your 3D file and select
                  your production process and material.
                </p>
              </div>
            </div>
            <svg
              width="45"
              height="3"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line strokeDasharray="10, 6" x1="0" y1="1" x2="249" y2="1" />
            </svg>
          </div>
          <div css={step}>
            <div className="stepIcon">
              <img src={placeOrderImg} alt="Industry Drawing" />
            </div>
            <div className="stepcontenttitle">
              <div className="steptitle">Place Order</div>
              <div className="stepcontent">
                <p>
                  After placing your order, production will start. You’ll
                  receive regular status updates.
                </p>
              </div>
            </div>
            <svg
              width="45"
              height="3"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line strokeDasharray="10, 6" x1="0" y1="1" x2="249" y2="1" />
            </svg>
          </div>
          <div css={step}>
            <div className="stepIcon">
              <img src={receivePartImg} alt="Boxes Drawing" />
            </div>
            <div className="stepcontenttitle">
              <div className="steptitle">Receive Parts</div>
              <div className="stepcontent">
                <p>
                  We provide quality assurance, quality reports, and delivery
                  tracking.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnonymousQuoteStart;
